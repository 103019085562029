import axios from "axios";

const AxiosService = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
  timeout: 5000,
  adapter: ["xhr", "http"],
});

export const NoAuthAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
  timeout: 5000,
  adapter: ["xhr", "http"],
});

export default AxiosService;
