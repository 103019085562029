import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import headerFooterData from "../store/apps/footer";
import career from "../store/apps/career";
import job from "../store/apps/job";
import blog from "../store/apps/blog";

export const store = configureStore({
  reducer: {
    headerFooterData,
    career,
    job,
    blog,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
