import AxiosService from "./axiosService";

const baseUrl = process.env.NEXT_PUBLIC_API_ENDPOINT;

const ApiService = {
  get: (path) => {
    return new Promise((resolve, reject) => {
      return AxiosService.get(baseUrl + path)
        .then((response) => {
          return response.status === 200 ? resolve(response) : reject(response);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            return resolve(error.response);
          }
          const { response } = error;
          reject(response);
        });
    });
  },
  post: (path, payload) => {
    return new Promise((resolve, reject) => {
      return AxiosService.post(baseUrl + path, payload)
        .then((response) => {
          return response.status === 201 || 204
            ? resolve(response)
            : reject(response);
        })
        .catch(({ response }) => reject(response));
    });
  },
};

export default ApiService;
