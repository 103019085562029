import React, { Suspense } from "react";
// import "../public/assets/css/custom.css";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../../public/assets/variable.css";
import "../../public/assets/variable.scss";
// import "../../public/assets/common.css";
import "../../public/assets/common.scss";
// import "../../public/assets/custom.css";
import "../../public/assets/custom.scss";
// import "../../public/assets/footer.css";
import "../../public/assets/footer.scss";
// import "../../public/assets/header.css";
import "../../public/assets/header.scss";
import "../../public/assets/industry.scss";
// import "../../public/assets/industry.css";
// import "../../public/assets/blog.css";
import "../../public/assets/blog.scss";
// import "../../public/assets/blog-detail.css";
import "../../public/assets/blog-detail.scss";
// import "../../public/assets/about-us.css";
import "../../public/assets/about-us.scss";
import "../../public/assets/services-detail.css";
import "../../public/assets/services-detail.scss";
// import "../../public/assets/careers.css";
import "../../public/assets/careers.scss";
// import "../../public/assets/case-studies.css";
import "../../public/assets/case-studies.scss";
// import "../../public/assets/contact.css";
import "../../public/assets/contact.scss";
// import "../../public/assets/request-proposal.css";
import "../../public/assets/request-proposal.scss";
// import "../../public/assets/developer.css";
import "../../public/assets/developer.scss";
// import "../../public/assets/range.css";
// import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import { Provider } from "react-redux";
import TagManager from 'react-gtm-module';
import { store } from "../store";
import { useEffect } from "react";
import Analytics from "../components/common/gtm/Analytics";

// const SnowFlake = dynamic(() => import("../components/common/SnowFlake"), {
//   ssr: false,
// });

function MyApp({ Component, pageProps }) {
  const GTM_ID = process.env.NEXT_PUBLIC_GTM_CODE;
  useEffect(() => {
    console.clear();
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);
  return (
    <Provider store={store}>
      <Suspense>
        <Analytics />
      </Suspense>
      <Component {...pageProps} />
      {/* <SnowFlake /> */}
    </Provider>
  );
}

export default MyApp;
