import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../../services/api/ApiService";
import { handleErrorMessages } from "../../../utils/common-functions";

export const getCareerPageList = createAsyncThunk(
  "getCareerPageList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.get(`/cms/pages/careers/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(handleErrorMessages(error.data));
    }
  }
);

const careerPageSlice = createSlice({
  name: "career",
  initialState: {
    careerPageList: {},
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = { ...state.loading, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCareerPageList.fulfilled, (state, action) => {
        state.careerPageList = action.payload;
        state.loading = false;
      })
      .addCase(getCareerPageList.pending, (state, action) => {
        state.loading = true;
      });
  },
});

export default careerPageSlice.reducer;

export const { setLoading } = careerPageSlice.actions;
