import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../../services/api/ApiService";
import { handleErrorMessages } from "../../../utils/common-functions";

export const getJobPostList = createAsyncThunk(
  "getJobPostList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.get(`/job-post/list/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(handleErrorMessages(error.data));
    }
  }
);

export const getJobFormList = createAsyncThunk(
  "getJobFormList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.get(`/cms/pages/job-details/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(handleErrorMessages(error.data));
    }
  }
);

const jobPageSlice = createSlice({
  name: "job",
  initialState: {
    jobPostList: {},
    jobFormList: {},
    loading: false,
    formListLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = { ...state.loading, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobPostList.fulfilled, (state, action) => {
        state.jobPostList = action.payload;
        state.loading = false;
      })
      .addCase(getJobPostList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getJobFormList.pending, (state, action) => {
        state.formListLoading = true;
      })
      .addCase(getJobFormList.fulfilled, (state, action) => {
        state.jobFormList = action.payload;
        state.formListLoading = false;
      });
  },
});

export default jobPageSlice.reducer;

export const { setLoading } = jobPageSlice.actions;
