import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../../services/api/ApiService";
import { handleErrorMessages } from "../../../utils/common-functions";

export const getLastestArticles = createAsyncThunk(
  "getLastestArticles",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.get(`/cms/pages/blog/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(handleErrorMessages(error.data));
    }
  }
);

const lastestArticlesSlice = createSlice({
  name: "lastestArticles",
  initialState: {
    lastestArticlesData: {},
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = { ...state.loading, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLastestArticles.fulfilled, (state, action) => {
        state.lastestArticlesData = action.payload;
        state.loading = false;
      })
      .addCase(getLastestArticles.pending, (state, action) => {
        state.lastestArticlesData = true;
      });
  },
});

export default lastestArticlesSlice.reducer;

export const { setLoading } = lastestArticlesSlice.actions;
